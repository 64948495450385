
import { computed, defineComponent, onMounted, reactive, Ref, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useToggle } from '@vant/use'
import { nanoid } from 'nanoid'
import { ImagePreview } from 'vant'
import uploadApi from '@/axios/upload'
import {
  ImageType,
  BusinessType,
  Source,
  CameraOptions,
  PiAssetsCriterionCode,
  PiApproveFileDesc
} from '@/enum'
import useOpenInfo from '@/hooks/openInfo'
import useQueryFileTemp from '@/hooks/useFileTemp'
import useSaveOpenInfo from '@/hooks/useSaveOpenInfo'
import { dataURLtoFile, unzip } from '@/utils/file'
import {
  getPictureAction,
  setApptitle,
  isInAppWebview,
  appUploadFileAction
} from '@/utils/native-app'
import { portfolioTtile, whyPortfolio, assetTitle, assetProof, custContent } from './content'
import beforeUpload from '@/utils/before-upload'
import useMutationCommon from '@/hooks/useMutationCommon'
import stepPercentage from '@/utils/number'
import { FileModel } from '@/interface'
import { mutateFn } from '@/graphql'
import { Message } from '@/components/Message'
import { useDict } from '@/hooks/useDict'

type ItemType = { selected: boolean; value: string }

export default defineComponent({
  setup () {
    const router = useRouter()
    const {
      openType,
      openInfo,
      step,
      title: headTitle,
      meta,
      saveOpenInfoOfVuex,
      saveCommonOfVuex
    } = useOpenInfo()
    const portfolioShow = ref(false)
    const custShow = ref(false)
    const show2 = ref(false)
    const showTip = ref(false)
    const showPerson = ref(false)
    const fileList: Ref<FileModel[]> = ref([])
    const form: any = reactive({
      assetText: '银行结单',
      assetType: '1',
      assetTypeList: [],
      assetChecked: [],
      attestation1: '',
      piAssetFileList: [
        {
          attestationCode: '1',
          attestationIsTrue: 1
        },
        {
          attestationCode: '2',
          attestationIsTrue: 1
        }
      ],
      piAssetCriteriaList: [
        {
          attestationCode: '1',
          attestationIsTrue: 0
        },
        {
          attestationCode: '3',
          attestationIsTrue: 0
        },
        {
          attestationCode: '4',
          attestationIsTrue: 0,
          attestation1: ''
        }
      ]
    })
    // 选择上传图片类型
    const [showAssetPicker, showAssetPickerToggle] = useToggle()
    // tips
    const [assetTipPicker, assetTipPickerToggle] = useToggle()
    // 上传文件
    const [fileActionState, fileActionToggle] = useToggle()

    // 相机选项
    const actionsList = [
      { key: CameraOptions.CAMERA, name: '拍摄' },
      { key: CameraOptions.PHOTO, name: '相册' },
      { key: CameraOptions.FILE, name: '选取文件' }
    ]
    // 查询字典
    const { dicResult } = useDict(['PI_APPROVE_FILE_DESC', 'PI_ASSETS_CRITERION_CODE'])
    const assetOption = computed(() => {
      const list =
        dicResult.value?.PI_APPROVE_FILE_DESC?.filter(
          (item: { name: string; value: string }) =>
            item.value !== PiApproveFileDesc.PI_APPROVE_FILE_DESC_3
        ) || []
      return list.map((item: ItemType) => {
        if (form.assetTypeList.includes(item.value)) {
          return {
            ...item,
            selected: true
          }
        } else {
          return {
            ...item,
            selected: false
          }
        }
      })
    })
    const assetCriterionList = computed(
      () =>
        dicResult.value?.PI_ASSETS_CRITERION_CODE?.filter(
          (item: { name: string; value: string }) =>
            item.value !== PiAssetsCriterionCode.PI_ASSETS_CRITERION_CODE_2
        ) ?? []
    )
    // 准则选项
    const changeChecked = (val: string[]) => {
      form.piAssetCriteriaList.forEach(
        (item: { attestationCode: string; attestationIsTrue: number; attestation1?: string }) => {
          if (form.assetChecked.includes(item.attestationCode)) {
            item.attestationIsTrue = 1
            if (item.attestationCode === PiAssetsCriterionCode.PI_ASSETS_CRITERION_CODE_4) {
              form.piAssetCriteriaList[2].attestation1 = form.attestation1
            }
          } else {
            item.attestationIsTrue = 0
            item.attestation1 = ''
          }
        }
      )
      const index = form.assetTypeList.indexOf(PiApproveFileDesc.PI_APPROVE_FILE_DESC_5)
      if (val.includes(PiAssetsCriterionCode.PI_ASSETS_CRITERION_CODE_4)) {
        if (index === -1) {
          form.assetTypeList.push(PiApproveFileDesc.PI_APPROVE_FILE_DESC_5)
        }
      } else {
        if (index !== -1) {
          form.assetTypeList.splice(index, 1)
        }
      }
    }
    const selectType = (item: ItemType) => {
      if (item.value === PiApproveFileDesc.PI_APPROVE_FILE_DESC_5) {
        if (!item.selected) return
        const index = form.assetChecked.indexOf(PiAssetsCriterionCode.PI_ASSETS_CRITERION_CODE_4)
        if (index === -1) {
          form.assetChecked.push(PiAssetsCriterionCode.PI_ASSETS_CRITERION_CODE_4)
        } else {
          form.assetChecked.splice(index, 1)
        }
      }
      item.selected = !item.selected
      form.assetTypeList = assetOption.value.map((item: ItemType) => {
        if (item.selected) {
          return item.value
        }
      })
    }
    const isActive = (item: ItemType) => {
      if (item.selected) {
        return 'active'
      }
      if (item.value === PiApproveFileDesc.PI_APPROVE_FILE_DESC_5) {
        if (item.selected) {
          return 'active'
        } else {
          return 'disabled'
        }
      }
      return ''
    }
    // 用户信息回填
    const initData = () => {
      Object.keys(form).forEach(val => {
        const res = openInfo.value[val] ? openInfo.value[val] : form[val]
        form[val] = res
      })
      // 缓存问题
      if (form.assetType === '0') {
        form.assetType = '1'
      }
    }
    // 判断是否在金马app内
    const isApp = computed(() => isInAppWebview())

    const uploadToApp = () => {
      if (!isApp.value) {
        return
      }
      fileActionToggle(true)
    }
    // 上传图片
    const uploadFile = (file: File) => {
      beforeUpload(file).then((res: any) => {
        const formatFile = new File([res], res.name, { type: res.type })
        fileList.value.push({
          url: '',
          status: 'uploading',
          message: '上传中...'
        })
        const params = {
          file: formatFile,
          imageType: ImageType.ACC_IMAGE_TYPE_801,
          businessType: BusinessType.ACC_FILE_BUSINESS_TYPE_8,
          kindSource: openType.value,
          source: Source.H5
        }
        uploadApi.uploadFile(params).then((res: any) => {
          const { openAccountUploadFile } = res
          if (openAccountUploadFile) {
            fileList.value[fileList.value.length - 1] = {
              url: openAccountUploadFile.data,
              status: 'done'
            }
          } else {
            console.log('上传失败')
            Message({
              direction: 'vertical',
              text: openAccountUploadFile?.reason?.desc || '上传失败，请重试'
            })
          }
        })
      })
    }
    // 上传图片前钩子
    const beforeRead = (file: File) => {
      uploadFile(file)
    }
    const onFileSelect = async (item: { key: string }) => {
      if (item.key === CameraOptions.FILE) {
        const params = {
          imageType: ImageType.ACC_IMAGE_TYPE_801,
          businessType: BusinessType.ACC_FILE_BUSINESS_TYPE_8,
          kindSource: openType.value,
          source: Source.H5
        }
        fileList.value.push({
          url: '',
          status: 'uploading',
          message: '上传中...'
        })
        const { filePath }: any = await appUploadFileAction(params)
        if (filePath.length) {
          fileList.value[fileList.value.length - 1] = {
            url: filePath[0],
            status: 'done'
          }
        } else {
          fileList.value.pop()
        }
      } else {
        const url = await getPictureAction(item.key)
        const base64 = unzip(url)
        const imgUrl = `data:image/png;base64,${base64}`
        const png = dataURLtoFile(imgUrl, `${nanoid()}.png`)
        uploadFile(png)
      }
    }
    // 获取缓存图片信息
    const imagesList = useQueryFileTemp({
      kindSource: openType.value
    })
    watch(imagesList, newValue => {
      fileList.value = newValue
        .filter((val: { imageType: number }) => val.imageType === ImageType.ACC_IMAGE_TYPE_801)
        .map((val: { remoteUrl: string }) => ({ url: val.remoteUrl, status: 'done' }))
    })

    // 删除图片
    const handleDel = async (file: { url: string }) => {
      const params = {
        kindSource: openType.value,
        remoteUrl: file.url
      }
      const res = await mutateFn('deleteTempFile', { params })
      const { ok, reason } = res
      if (ok) {
        fileList.value = fileList.value.filter((val: { url: string }) => val.url !== file.url)
        return true
      } else {
        Message({ text: reason?.desc || '请求异常', direction: 'vertical' })
        return false
      }
    }

    const title = portfolioTtile
    const content = whyPortfolio
    const arTitle = assetTitle
    const arContent = assetProof
    const isDisabled = computed(
      () =>
        !(fileList.value.length && !fileList.value.some((s: FileModel) => s.status === 'uploading'))
    )

    // 保存下一步调用接口
    const saveNext = () => {
      const percentage = stepPercentage(step)
      const params = {
        formType: 'openAccount',
        meta: meta.value,
        content: JSON.stringify(form)
      }
      const common = {
        openType: openType.value,
        openStatus: 0,
        btnText: '继续开户',
        remark: `已完成${percentage}%，请继续完善资料`,
        step
      }
      useSaveOpenInfo(params).then(() => {
        useMutationCommon(common)
        saveCommonOfVuex({ info: common })
        saveOpenInfoOfVuex({ info: form })
        router.push({ name: 'statement' })
      })
    }

    const handleNext = () => {
      const isFileSelected = form.assetTypeList.every((item: string) => !item)
      if (isFileSelected) {
        Message({
          text: '请选择文件类型'
        })
        return
      }
      const notOtherList = assetOption.value.filter(
        (item: ItemType) => item.value !== PiApproveFileDesc.PI_APPROVE_FILE_DESC_5
      )
      const isNotOther = notOtherList.some((item: ItemType) => item.selected)
      const isOther = assetOption.value.filter(
        (item: ItemType) => item.value === PiApproveFileDesc.PI_APPROVE_FILE_DESC_5
      )[0].selected
      if (isNotOther) {
        // 选了非其他
        if (isOther) {
          // 其他
          form.piAssetFileList = [
            {
              attestationCode: '1',
              attestationIsTrue: 1
            },
            {
              attestationCode: '2',
              attestationIsTrue: 1
            }
          ]
        } else {
          form.piAssetFileList = [
            {
              attestationCode: '1',
              attestationIsTrue: 1
            },
            {
              attestationCode: '2',
              attestationIsTrue: 0
            }
          ]
        }
      } else {
        form.piAssetFileList = [
          {
            attestationCode: '1',
            attestationIsTrue: 0
          },
          {
            attestationCode: '2',
            attestationIsTrue: 1
          }
        ]
      }
      saveNext()
    }

    onMounted(() => {
      setApptitle(headTitle)
      initData()
    })
    // 查看示例
    const viewExp = () => {
      ImagePreview({
        images: [
          require('../../assets/images/futu.png'),
          require('../../assets/images/huifeng.png'),
          require('../../assets/images/yaocai.png'),
          require('../../assets/images/zhada.png')
        ],
        closeable: true
      })
    }
    return {
      viewExp,
      form,
      fileList,
      initData,
      isDisabled,
      handleNext,
      assetOption,
      title,
      content,
      arTitle,
      arContent,
      show2,
      showTip,
      showPerson,
      portfolioShow,
      custShow,
      custContent,
      showAssetPicker,
      showAssetPickerToggle,
      assetTipPicker,
      assetTipPickerToggle,
      // delRead,
      isApp,
      uploadToApp,
      beforeRead,
      fileActionState,
      fileActionToggle,
      actionsList,
      onFileSelect,
      handleDel,
      assetCriterionList,
      selectType,
      isActive,
      changeChecked,
      PiAssetsCriterionCode,
      PiApproveFileDesc
    }
  }
})
