import { queryApi } from '@/graphql/query'
import { useQuery } from '@vue/apollo-composable'
import { ref } from 'vue'

export const useDict = (param: string[]) => {
  const dicResult: any = ref()
  const { onResult } = useQuery(
    queryApi.findDictOpenAccount,
    {
      params: {
        markList: param
      }
    },
    {
      fetchPolicy: 'no-cache'
    }
  )
  onResult(res => {
    const { findDictOpenAccount } = res?.data
    dicResult.value = findDictOpenAccount.data
  })
  return { dicResult }
}
