export const portfolioTtile = '什么是投资组合？'

export const whyPortfolio = `
  <p>在本声明的签订日期，在计算以下任何一个或多于一个项目时，拥有由证券、存款证、存款及/或现金组成，并且不少于港币$8,000,000 (或等值外币)的投资组合﹔</p>
  <br/><p>阁下个人帐户内的投资组合﹔</p><br/>
  <p>阁下和阁下配偶或其任何子女的联名户口内的投资组合﹔</p><br/>
  <p>阁下和除阁下配偶或其任何子女外之人士的联名户口内的投资组合，属于阁下的部份﹔及</p><br/>
  <p>阁下在本声明的签订日期全资拥有，而且主要业务是持有投资项目的法团的投资组合。</p>
`

export const assetTitle = '资产凭证'

export const assetProof = `
  <p>在最近3个月内由保管人(例如︰银行、持牌法团、或其他保管人)发出不少于港币$8,000,000等值资产的帐户结单或证明书。资产可包括现金、股票、基金、存款证等。</p>
`
export const custContent = `
<p>(a)  主要业务是作为另一人的证券或其他财产的保管人（不论是以信托或合约形式保管）的法团；或</p>
<p>(b)   业务包括作为另一人的证券或其他财产的保管人（不论是以信托或合约形式保管）的下述人士：</p>
<p>(i)     认可财务机构；</p>
<p>(ii)     并非认可财务机构但根据香港以外地方的法律受规管的银行；</p>
<p>(iii)     持牌法团；</p>
<p>(iv)     经营提供投资服务的业务并根据香港以外地方的法律受规管的人。</p>`
